.rave-entry-container .rave-entry-item {
    width: 100%;
    display: inherit;
}
.rave-entry-container .rave-entry-item.rave-entry-active {
    background-color: lightgreen;
}

.rave-entry-container .rave-entry-item input,
.rave-entry-container .rave-entry-item .ant-picker,
.rave-entry-container .rave-entry-item .ant-select .ant-select-selector {
    background-color: transparent;
}
