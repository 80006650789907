.data-container .title-image {
    aspect-ratio: 4 / 3;
    /*text-align: center;*/
}

.data-container .title-image .ant-image {
    height: 100%;
    width: 100%;
}

.data-container .title-image .ant-image .ant-image-img {
    /*width: unset;*/
    height: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    object-fit: cover;
}
.ant-table-cell .title-image {
    aspect-ratio: 4 / 3;
}
/*
.ant-table-cell .title-image {
    aspect-ratio: 4 / 3;
    text-align: center;
    vertical-align: middle;
    display: inline;
}
*/
.ant-table-cell .title-image .ant-image {
    height: 100%;
}
.ant-table-cell .title-image .ant-image .ant-image-img {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
}
.panel-image .title-image {
    aspect-ratio: 4 / 3;
}
.panel-image .title-image .ant-image {
    height: 100%;
}
.panel-image .title-image .ant-image .ant-image-img {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
}
