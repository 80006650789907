.ant-table-tbody tr.ant-table-row.rave-assigned {
    background-color: beige;
}
.ant-table-tbody tr.ant-table-row.rave-relocated {
    background-color: yellow;
}
.ant-table-tbody tr.ant-table-row.rave-assigned-relocated {
    background-color: yellowgreen;
}
.cses-product-tree {
    margin: 0.2em;
    overflow-y: scroll;
    max-height: calc(100vh - 400px);
    scrollbar-width: thin;
}
.cses-product-tree::-webkit-scrollbar {
    width: 1em;
}
/*
.cses-product-tree::-webkit-scrollbar-track {background: #f1f1f1;}
.cses-product-tree::-webkit-scrollbar-thumb {background: #888;}
.cses-product-tree::-webkit-scrollbar-thumb:hover {background: #555;}
*/
.ant-tree.cses-product-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: unset;
    font-weight: bold;
    color: rgba(0, 0, 0, .85)
}
/*
.ant-tree.cses-product-tree .ant-tree-node-content-wrapper.ant-tree-node-selected .anticon {stroke: white;stroke-width: 30;font-size: 50px;}
*/
.ant-tree.cses-product-tree .ant-tree-node-content-wrapper.ant-tree-node-selected .anticon svg path {fill: rgba(0, 0, 0, .85)}

/*
.ant-tree.cses-product-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {background-color: unset;font-weight: bold;}
*/

.ces-breadcrumb-container {display: inline-block;margin-left: 1em;}

.ant-menu.ant-menu-root.cses-main-menu {justify-content: right;padding-right: 1rem;}

#main-header .ant-menu-horizontal {line-height: 4em}
.ces-sidebar-accordion.ant-collapse > .ant-collapse-item > .ant-collapse-header {padding: .4em}
/*.ces-sidebar-accordion.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {padding: .4em .8em .4em 1.6em;}*/
.ces-sidebar-accordion.ant-collapse  > .ant-collapse-content-box {padding: 0;}
.ces-sidebar-accordion.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {padding: 0;}
.ces-sidebar-accordion.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {right: .2em}

.ces-sidebar-accordion.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {flex: auto;overflow-x: hidden;white-space: nowrap;}

/* disable the overflow X accordion in Sidebar */
.ces-sidebar-accordion .ant-collapse-item {overflow-x: hidden;}

.ces-rave-table .ant-table-pagination.ant-pagination {margin: .4em 0}

/* Rave Card and transition*/

.ces-rave-card > .ant-card-head h2 {display: inline-block; margin-right: .4em}
.ces-rave-card > .ant-card-head h2 > button.ant-btn {margin-right: .2em}
.ces-rave-card > .ant-card-head h2::after {content: ":"}
.ces-rave-card > .ant-card-head h2 + i::after {content: '\0000a0\0021D2\0000a0\0000a0'; overflow: hidden;}

.ces-rave-card.ant-card > .ant-card-body > .ant-card /*.ant-card-body*/ {
    max-height: 30em;
    overflow: hidden;
    /*transition: height .5s linear 0s;*/
    transition-property: max-height, border;
    transition-duration: .3s;
    transition-timing-function: linear;
    transition-delay: .1s;
}
.ces-rave-card.ant-card > .ant-card-body > .ant-card.ces-collapse /*.ant-card-body*/ {max-height: 0; border: none}


/* disabled color */

.ant-input-outlined.ant-input-disabled, .ant-input-outlined[disabled],
.ant-input-number-disabled .ant-input-number-input, .ant-input-number-readonly .ant-input-number-input,
.ant-input-number-outlined.ant-input-number-disabled, .ant-input-number-outlined[disabled],
.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-disabled .ant-input-number-group-addon,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.65);
    background-color: rgba(0, 0, 0, 0.01);
/*    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="30" style="font: normal normal bold 20px Verdana;"><text y="15">x</text></svg>'), not-allowed;*/
    cursor: pointer;
}

/* quill borders RAVE*/

.ql-container {
    font-family: Arial, Helvetica, sans-serif;
}

.ant-card .quill .ql-toolbar {
    /*
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    */
    border-radius: 4px 4px 0 0;
}
.ant-card .quill .ql-container {
    border-radius: 0 0 4px 4px;
}
.ant-card .quill .ql-container.ql-disabled {
    border-radius: 4px;
    border-color: #d9d9d9;
}
